import React from 'react'

import Layout from '../components/Layout'
import SEO from "../components/seo"
import BookingForm from "../components/BookingForm"
import PageBanner from '../components/PageBanner'
import banner_image from '../images/page-banners/food-drinks.jpg'

import food_drinks_1 from "../images/food-drinks/1.jpg"
import food_drinks_2 from "../images/food-drinks/2.jpg"
import food_drinks_3  from "../images/food-drinks/3.jpg"
import food_drinks_4  from "../images/food-drinks/4.jpg"
import food_drinks_5  from "../images/food-drinks/5.jpg"
import food_drinks_6  from "../images/food-drinks/6.jpg"
import food_drinks_7  from "../images/food-drinks/7.jpg"
import food_drinks_8  from "../images/food-drinks/8.jpg"
import food_drinks_9  from "../images/food-drinks/9.jpg"
import food_drinks_10  from "../images/food-drinks/10.jpg"
import food_drinks_11  from "../images/food-drinks/11.jpg"

const FoodDrinksPage = () => {

  return (

    <Layout sidebarComponent={<BookingForm colWidth="col-md-12" />} className="food-drinks-page">
      
      <SEO title="Food & Drinks" />

      <PageBanner image={banner_image} title="Food & Drinks"/>

      <div className="container">
        <div className="inner-content">
          <div className="food-drinks-text">
            <h2>Restaurant</h2>  
            <p>We are renowned for the cuisine in our restaurant, where we offer an innovative European-Moroccan fusion, based on local produce and flavours - a surprising change from the usual fare of Tagine and Couscous.</p>
            <p>We offer simple but delicious lunches, and in the evening, we offer guests a varied menu which changes every day</p>
          </div>

          <ul className="image-gallery columns-2">
            <li><img src={food_drinks_1} alt=""/></li>
            <li><img src={food_drinks_2} alt=""/></li>
          </ul>

          <ul className="image-gallery columns-3">
            <li><img src={food_drinks_3} alt=""/></li>
            <li><img src={food_drinks_4} alt=""/></li>
            <li><img src={food_drinks_5} alt=""/></li>
            <li><img src={food_drinks_6} alt=""/></li>
            <li><img src={food_drinks_7} alt=""/></li>
            <li><img src={food_drinks_8} alt=""/></li>
            <li><img src={food_drinks_9} alt=""/></li>
            <li><img src={food_drinks_10} alt=""/></li>
            <li><img src={food_drinks_11} alt=""/></li>
          </ul>
          
        </div>
      </div>


    </Layout>
    
  )
}

export default FoodDrinksPage